import React, { useState, useEffect } from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom ParallaxImg
 * 
 * Parallax Image
 */
function ParallaxImg (props) {
  const {
    className = "",
    image = "",
    alt = "",
    imagePosition = "right",
    withVideo = false,
    ...other
  } = props;

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return <div
    className={`${css["atom__parallax-img-container"]} ${className}`}
    style={{ transform: `translateY(${offsetY * 0.3}px)` }}
    {...other}
  >
    <img
      src={image}
      alt={alt}
      className={css[ withVideo ? "img" : "img-wide"]}
      data-position={imagePosition}
    />
  </div>;
}

export default ParallaxImg;
