import React from 'react';

import Abstract from 'components/atoms/abstract';
import Client from 'components/atoms/client';
import Title from 'components/atoms/title';
import Typography from 'components/atoms/typography';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism SectionClients
 * 
 * Section containing clients
 */
function SectionClients (props) {
  const {
    className = "",
    title = "Clientes",
    list = [],
    children,
    ...other
  } = props;

  return <div
    className={`${css["organism__section-clients-container"]} ${className}`}
    {...other}
  >
    <div
      className={css["content"]}
      data-content-wrapper="true"
    >
      <Title
        level="3"
        color="white"
        className={css["title"]}
      >{title}</Title>
      {
        list.length > 0 &&(<>
          <div className={css["list"]}>
            {
              list.map(({title, url, alt}, index) => (
                <a
                  key={index}
                  href={url}
                  target="_blank"
                  rel="noreferrer noopener"
                  title={alt || title}
                >
                  <Typography type="inter">
                    / {title}
                  </Typography>
                </a>
              ))
            }
          </div>
          {
            list.map((client, index) => (
              <Client
                key={index} {...client}
                className={css[`${client.iconName}`]}
                style={{ background: 'white'}}
              />
            ))
          }
        </>)
      }

      <Abstract className={css["abstract"]} />
      <Abstract type="3" className={css["abstract-circles"]} transform={180}/>
    </div>
    <Abstract
      type="4"
      className={css["abstract-circle-grid"]}
      transform={90}
    />
    <div className={css["abstract-diagonal"]}>
      <Abstract type="12" className={css["abstract-diagonal-grid"]} />
      <Abstract type="12" className={css["abstract-diagonal-grid"]} />
    </div>
    {children}
  </div>;
}

export default SectionClients;