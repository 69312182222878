import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

import ParallaxImg from 'components/atoms/parallax-img';
import Modal from 'components/molecules/modal';
import PlayButton from 'components/atoms/play-button';
import Title from 'components/atoms/title';
import Abstract from 'components/atoms/abstract';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism SectionInfo
 * 
 * Section that contains a title and a video or image
 */
function SectionInfo (props) {
  const {
    className = "",
    title,
    // dark = false,
    image = "",
    imagePosition = "right",
    video = "",
    width = "100%",
    height = "100%",
    alt = "",
    children,
    ...other
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenCloseModal = () => {
    setIsModalOpen(oldState => !oldState);
  };

  return <div
    className={`${css["organism__section-info-container"]} ${className}`}
    {...other}
  >
    <div className={css["box"]} />
    <div
      className={css["content"]}
      data-content-wrapper="true"
      data-video={!!video}
    >
      {
        image &&
          <div className={css["info"]} data-video={!!video}>
            <Title color="white" className={css["title"]}>
              <ReactMarkdown>{title}</ReactMarkdown>
            </Title>
            <ParallaxImg
              image={image}
              alt={alt}
              imagePosition={imagePosition}
              withVideo={!!video}
            />
            {
              video &&
              <PlayButton
                className={css["btn"]}
                dark
                shadow
                onClick={handleOpenCloseModal}
              >
                Play
              </PlayButton>
            }
            <Abstract
              type="1"
              className={css["abstract"]}
              speed={45}
            />
            <Abstract
              type="3"
              className={css["abstract-group"]}
              transform={90}
              speed={60}
            />
            {
              video &&
              <Abstract
                type="5"
                className={css["abstract-curves"]}
                disabled
              />
            }
          </div>
      }
    </div>

    {
      isModalOpen && video &&
      <Modal onClick={handleOpenCloseModal} dark className={css["modal"]}>
        {
          video &&
          <iframe
            width={width}
            height={height}
            src={video}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; gyroscope; picture-in-picture"
            allowFullScreen={true}
          ></iframe>
        }
      </Modal>
    }

    <Abstract
      type="8"
      className={css["info-grid"]}
      transform={90}
      speed={80}
    />
    <Abstract
      type="3"
      className={css["info-circles-group"]}
      transform={180}
      speed={45}
    />
    {children}
  </div>;
}

export default SectionInfo;
