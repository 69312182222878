import React from 'react';
import Head from 'next/head';

/**
 * Atom TitleHead
 * 
 * Head Title
 */

function TitleHead (props) {
  const {
    page = "",
  } = props;

  return <Head>
    <title>{page ?  `Eyxo | ${page}`: 'Eyxo'}</title>
    <meta property="og:site_name" content={page ?  `Eyxo | ${page}`: 'Eyxo'} />
    <meta name="twitter:site" content={page ?  `Eyxo | ${page}`: 'Eyxo'} />
    <meta property="og:title" content={page ?  `Eyxo | ${page}`: 'Eyxo'} />
    <meta property="twitter:title" content={page ?  `Eyxo | ${page}`: 'Eyxo'} />
    <meta property="og:image:alt" content={page ?  `Eyxo | ${page}`: 'Eyxo'} />
  </Head>;
}

export default TitleHead;
