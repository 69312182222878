import React from 'react';

import Typography from 'components/atoms/typography';

import { PlayButtonRound, PlayButtonGroup } from 'components/icons';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom PlayButton
 * 
 * Play Button
 */
function PlayButton (props) {
  const {
    className = "",
    alt = "Play",
    shadow = false,
    dark = false,
    onClick,
    children,
    ...other
  } = props;

  return <button
    className={`${css["atom__play-button-container"]} ${className}`}
    data-theme={dark}
    onClick={onClick}
    alt={alt}
    title={alt}
    {...other}
  >
    {shadow ?
      <PlayButtonGroup className={css["icon"]}/> :
      <PlayButtonRound className={css["icon"]}/>
    }
    <Typography type="helvetica" className={css["text"]}>
      {children}
    </Typography>
  </button>;
}

export default PlayButton;
