/* eslint-disable max-len */

export const list = [
  {
    caseId: "tiktok",
    title: "Tik Tok",
    image: "/images/pictures/CaseTikTok.png",
    alt: "Case TikTok",
    url: "/cases/tiktok",
  },
  {
    caseId: "conquer",
    title: "Con Quer",
    image: "/images/pictures/CaseConquer.png",
    alt: "Case Conquer",
    url: "/cases/conquer",
    variant: "right"
  },
  {
    caseId: "tembici",
    title: "Tem Bici",
    image: "/images/pictures/CaseTembici.png",
    alt: "Case Tembici",
    url: "/cases/tembici",
  },
  {
    caseId: "americanas",
    title: "Ame Rica Nas",
    image: "/images/pictures/CaseAmericanas.png",
    alt: "Case Americanas",
    url: "/cases/americanas",
    variant: "right"
  },
  {
    caseId: "sxsw",
    title: "SX SW",
    image: "/images/pictures/CaseSXSW.png",
    alt: "Case SX SW",
    url: "/cases/sxsw",
  },
  {
    caseId: "99",
    title: "99",
    image: "/images/pictures/Case99.jpg",
    alt: "Case 99",
    url: "/cases/99",
    variant: "right"
  },
];

export const Info = {
  title: "**SEM**\n\nFORMATO,\n\nPOR\n\nPRINCÍPIO",
  image: "/images/pictures/InfoCases.png",
  alt: 'Sem formato, por princípio.',
};

export const CasesInfo = {
  title: 'Cases',
  description: 'Sem formato, por princípio. Assim buscamos que sejam as nossas produções: ousadas, diversas e cheias de propósito. Conheça algumas delas.',
  list
};

export const Clients = {
  list: [
    { title: 'Tembici', iconName: 'Tembici', alt: 'Tembici', url: 'https://www.tembici.com.br/' },
    { title: 'Americanas', iconName: 'Americanas', alt: 'Americanas', url: 'https://www.americanas.com.br/' },
    { title: 'TikTok', iconName: 'Tiktok', alt: 'TikTok', url: 'https://www.tiktok.com/pt-BR' },
    { title: '99', iconName: 'Svg99', alt: '99', url: 'https://99app.com' },
    { title: 'Next', iconName: 'Next', alt: 'Next', url: 'https://next.me' },
    { title: 'Banco24Horas', iconName: 'Bank24H', alt: 'Banco 24Horas', url: 'https://www.banco24horas.com.br' },
    { title: 'Pier', iconName: 'Pier', alt: 'Pier', url: 'https://www.pier.digital' },
    { title: 'Panvel', iconName: 'Panvel', alt: 'Panvel', url: 'https://www.panvel.com/' },
  ]
};
