import React from 'react';
import Link from 'next/link';

import Title from 'components/atoms/title';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule Case
 * 
 * Case Component
 */
function Case (props) {
  const {
    className = "",
    caseId = "",
    title = "",
    color = "white",
    image = "",
    alt = "",
    variant = "left",
    url = "/",
    children,
    ...other
  } = props;

  return <div
    className={`${css["molecule__case-container"]} ${className}`}
    data-variant={variant}
    {...other}
  >
    <Link href={url} passHref>
      <a title={alt || title || caseId}>
        <Title
          level="2"
          color={color}
          className={css["title"]}
          data-variant={variant}
        >
          {title}
        </Title>
        <img src={image} alt={alt || title} className={css["img"]}/>
      </a>
    </Link>
    {children}
  </div>;
}

export default Case;
