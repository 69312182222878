export function hitEvent (category, action, label) {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action,
      {
        event_category: category,
        event_label: label
      }
    );
  }
}

export function pageView (opts) {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'page_view', opts);
  }
}
