import React from 'react';

import Abstract from 'components/atoms/abstract';
import Title from 'components/atoms/title';
import Typography from 'components/atoms/typography';
import Case from 'components/molecules/case';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism SectionCases
 * 
 * Section containing cases
 */
function SectionCases (props) {
  const {
    className = "",
    title = "Cases",
    description = "",
    list = [],
    children,
    ...other
  } = props;

  return <div
    className={`${css["organism__section-cases-container"]} ${className}`}
    {...other}
  >
    <div
      className={css["content"]}
      data-content-wrapper="true"
    >
      <div className={css["info"]}>
        <Title
          level="3"
          color="lightgray"
          className={css["title"]}
        >{title}</Title>
        <Typography
          color="lightgray"
          className={css["desc"]}
        >{description}</Typography>
      </div>
      {
        list.length > 0 &&
        list.map((c, index) => (
          <Case key={index} {...c} className={css["case"]}>
            <Abstract
              type={index % 2 === 0 ? '9' : '11' }
              data-variant={index % 2 === 0 ? 'left' : 'right'}
              className={css["abstract"]}
              speed={index % 2 === 0 ? 50 : 45}
            />
          </Case>
        ))
      }
    </div>
    {children}
  </div>;
}

export default SectionCases;
