import React from 'react';

import * as Icons from 'components/icons';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Client
 * 
 * Client
 */
function Client (props) {
  const {
    className = "",
    alt = "",
    iconName = "",
    width = '80%',
    height = '80%',
    ...other
  } = props;

  const Icon = Icons[iconName];

  return <div
    className={`${css["atom__client-container"]} ${className}`}
    alt={alt || iconName}
    title={alt || iconName}
    {...other}
  >
    {Icon && <Icon className={css["icon"]} width={width} height={height} />}
  </div>;
}

export default Client;
